$home_cover_url: "https://farm5.staticflickr.com/4489/37070405844_30d4af8484_o_d.jpg";

.mitmachen-select {
  > .column {
    text-align: center;
  }

  .symbol {
    max-width: 10em;
    filter: drop-shadow(1px 1px 1px $primary);
    transition: all .4s;
  }

  span {
    color: $primary;
    display: block;
  }

  a:hover, a:focus {
    .symbol {
      filter: drop-shadow(3px 3px 3px $primary);
      transform: translate(-1px, -1px);
    }
  }
}

.symbol {
  &--stroke {
    path {
      fill: none;
      stroke: $secondary;
      stroke-width: .5em;
      stroke-linejoin: round;
    }
  }
  &:not(.symbol--stroke) {
    path {
      fill: $secondary;
    }
  }
}
/*.about {
  $imgWidth: 14em;
  $sideWidth: $imgWidth + 2 * $gutter;
  > * {
    margin: 4 * $gutter 0;

    > img {
      width: $imgWidth;
      border: 2px solid $taubenrot;
      border-radius: .3em;
    }
    &:nth-child(2n + 1) {
      img {
        float: right;
        margin-right: -1 * $sideWidth;
      }
      padding-right: $sideWidth;
    }
    &:nth-child(2n) {
      img {
        float: left;
        margin-left: -1 * $sideWidth;
      }
      padding-left: $sideWidth;
    }
  }
}*/

.about {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  > div {
    margin: 2 * $gutter 0;
    width: 60%;

    + div {
      padding-right: 2 * $gutter;
      padding-left: 0;
      text-align: right;
    }

    @include mobile {
      padding-right: $gutter;
    }

    + img {
      transform: rotate(2deg);
    }

    > * {
      max-width: 33rem;
      margin-left: auto;
      margin-right: 0;
    }

    > h3 {
      font-size: 1.7em;
    }
  }
  > img {
    margin: 2 * $gutter 0;
    max-width: 40%;
    max-height: 15em;
    transform: rotate(358deg);

    // this is a hack to avoid antialiasing errors
    background-color: $taubenrot;

    @extend %polaroid;

    + div {
      padding-left: 2 * $gutter;
      padding-right: 0;

      @include mobile {
        padding-left: $gutter;
      }

      > * {
        margin-right: auto;
        margin-left: 0;
      }
    }



    @for $i from 1 through 6 {
      &:nth-of-type(6n+#{$i}) {
        @extend .border-#{$i}
      }
    }
  }
}

.home__cover {
  background-image: linear-gradient(to top, rgba(0,0,0,.1), rgba(0,0,0,0.0)), url($home_cover_url);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 15%;
  padding: 2.5rem 1rem;
  padding-bottom: 6rem;

  > .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @include tablet {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  @include desktop {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
}

.section.home__cover-overlap {
  padding-top: 0;
  padding-bottom: 0;
}

.container.home__cover-overlap {
  margin-top: -5rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  @include tablet-only {
    margin-top: -3rem;
  }

  @include mobile {
    flex-direction: column;
  }
}

.home__intro {
  max-width: 37em;
  background: rgba(0,0,0,.6);
  background: $freude-dark;
  padding: 1.5em;
  color: #222;
  font-size: 1.2em;

  @include tablet {
    order: 2;
  }

  p + p {
    margin-top: .5em;
  }
}

.mission {
  color: $secondary;
  font-size: 3em;
  font-weight: 500;
  text-shadow: 2px 2px 2px #222;
  text-shadow: 0 0 1em rgba(0,0,0,0.4), 2px 2px 5px rgba(0,0,0,.7);
  line-height: 1.1;
  margin: 5rem 1.5rem 0;

  @include mobile {
    font-size: 2.2em;
  }
  @include widescreen {
    font-size: 4em;
  }

  > span {
    display: block;
    + span {
      font-family: $family-accent;
      color: $freude;

      @include widescreen {
        text-indent: 1.25em;
      }
      @include fullhd {
        text-indent: 2.5em;
      }
    }
  }
}


.subscribe-form {
  text-align: center;
  max-width: 50em;
  margin: auto;
  /*display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  label {
    width: 100%;
  }
  input[type=email] {
    margin: .75rem;
  }*/
}
