$gallery-margin: 0.25em;
$gallery-max-width: 320px;

@function gallery-width($screen-width) {
  @return 100% / ceil(($screen-width - (2 * $gap)) / $gallery-max-width); }

@mixin gallery($gallery-margin: $gallery-margin, $gallery-max-width: $gallery-max-width, $gallery-default-width: $gallery-max-width) {
  display: flex;
  flex-direction: row;
  margin: 0 #{-1 * $gallery-margin};

  &:not(.one-row) {
    flex-wrap: wrap;

    .gallery-item {
      width: $gallery-default-width; } }

  > .title {
    width: 100%;
    margin-left: $gallery-margin;
    margin-right: $gallery-margin; }

  .gallery-item {
    padding: $gallery-margin;
    display: block;

    > .image {
      overflow: hidden; } }

  .container &:not(.one-row) {
    .gallery-item {
      @include mobile {
        width: 50%; }
      @include tablet {
        width: gallery-width($tablet); }
      @include desktop {
        width: gallery-width($desktop - (2 * $gap)); }
      @include widescreen {
        width: gallery-width($widescreen - (2 * $gap)); }
      @include fullhd {
        width: gallery-width($fullhd - (2 * $gap)); } } } }


.gallery {
  @include gallery($gallery-margin, $gallery-max-width); }

.gallery-image {
  object-fit: cover;
  transition: transform 1.2s;

  .gallery-item:hover &,
  .gallery-item:focus & {
    transform: scale(1.06); } }

