$white: #fff;
$black: #000;

$taubenrot: #cf2328;
$taubenrot-dark: mix($taubenrot, #653);
$taubenrot-dark: #871215;

$freude: #ffd800;
$freude-light: #f6e77d;
$freude-light: #f6eb70;
$freude-dark: #f0c864;
$freude-dark: #f0d050;

$mine-shaft: #333;

$grey-dark: #653;
$shadow: #875;
$dusty: #999999;

$alto: #d9d9d9;

//

$primary: $mine-shaft;
$secondary-hover: $taubenrot-dark;
$secondary: $taubenrot;
$accent: $freude;
$link: $taubenrot;
$footer-background-color: $freude-dark;
$title-color: $taubenrot;
$heading-color: $taubenrot;

$text: $mine-shaft;
$border: $alto;

$body-background-color: $freude-light;

$navbar-background-color: #1c1c1c;

$navbar-item-color: $dusty;
$navbar-item-hover-color: $white;
$navbar-item-hover-background-color: transparent;
$navbar-item-active-color: $white;
$navbar-item-active-background-color: transparent;
$navbar-item-img-max-height: 1.75rem;

$navbar-tab-hover-background-color: transparent;
$navbar-tab-hover-border-bottom-color: $link;
$navbar-tab-active-color: $link;
$navbar-tab-active-background-color: transparent;
$navbar-tab-active-border-bottom-color: $link;
$navbar-tab-active-border-bottom-style: solid;
$navbar-tab-active-border-bottom-width: 3px;

$navbar-dropdown-background-color: $navbar-background-color;

$strongWeight: 500;
$normalWeight: 300;

$family-primary: Ubuntu, Helvetica, Arial, sans-serif;
$family-accent: "Gloria Hallelujah", sans-serif;
$body-line-height: 1.5;
$body-size: 14px;

$container-width: 55rem;
$content-heading-color: $secondary;
/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
/// @see {mixin} respond-to
// $breakpoints: (
//   "small": (min-width: 320px),
//   "medium": (min-width: 768px),
//   "large": (min-width: 1024px),
//   x-large: (min-width: 1680px)
// )


$gutter: 1.5rem;

$button-color: $freude-light;
$button-background-color: $taubenrot;
$button-border-color: transparent;
$button-hover-color: $freude;
$button-hover-border-color: $freude;
$button-focus-color: $freude-dark;
$button-focus-border-color: $freude-dark;
$button-active-color: $freude-dark;
$button-active-border-color: $freude-dark;

.button:link, .button:visited {
  color: $button-color; }

$pagination-border-color: $taubenrot;
$pagination-hover-border-color: $taubenrot-dark;
$pagination-focus-border-color: $taubenrot-dark;

$page-header-background-color: $freude-dark;
$page-header-image-background-color: $freude-dark;
