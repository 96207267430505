@import "../bulma/sass/layout/footer.sass";

.logo--mission-manifest {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  > svg {
    width: 4em; }

  > span {
    text-transform: uppercase;
    font-weight: 600;
    line-height: 1;
    padding: 0 .5em;
    font-size: 1.2em;
    color: $text; } }

.logo--schoenstatt > a {
  svg {
    width: 7rem;
    height: 7rem;
    float: right;
    opacity: .8; }

  &:hover {
    color: $taubenrot;
    svg {
      opacity: 1; } } }
