.navbar-item {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  font-weight: 200; }

.navbar-item, .navbar-link {
  &:link,
  &:visited {
    color: $dusty; } }

.site-title {
  font-size: 1.3rem;
  font-weight: 200;
  line-height: 1;
  text-shadow: 0 -1px 3px rgba(0, 0, 0, 0.5);
  font-family: $family-accent;

  &:link {
    color: $secondary; }

  > svg {
    width: 1.2em;
    margin-top: -.2em;
    margin-bottom: -.2em;
    margin-right: .5em;

    > path {
      fill: $taubenrot; } } }
