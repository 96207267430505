.image-attribution {
  padding: .2em;

  &, & a {
    font-size: .6rem;
    font-weight: lighter;
    color: #555; } }

figure {
  position: relative;

  > .image-attribution {
    position: absolute;
    top: 100%; }

  &.is-wide {
    margin-left: 0;
    margin-right: 0;
    img {
      object-fit: cover;
      max-height: 18em;
      width: 100%; } }

  &.is-right {
    float: right;
    clear: right;
    margin-right: 0; }
  &.is-left {
    float: left;
    clear: left;
    margin-left: 0; }
  &.is-left,
  &.is-right {
    &, .content & {
      max-width: 45%;
      margin-top: 0; } }

  &:not(.is-right):not(.is-left):not(.is-wide) {
    margin: auto; } }

.figcaption {
  padding: .5em;

  &--inline {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    padding-right: 1em;
    color: $white;
    text-shadow: 2px 2px 2px #111;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, transparent 100%); } }

