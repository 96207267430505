.stationen {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .___diabled {
    flex-shrink: 0;
    border-radius: .1em;
    border: 1px solid $grey-dark;
    background-color: $freude-dark;
    height: 10em;
    //transform: rotate(2deg);
    > a {
      font-weight: normal;
      color: $primary;
    }
  }

  > .station {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

$thumb_size: 15rem;
.station {
  margin: .4rem 1rem;
  padding-left: $thumb_size + $gutter;

  border: .2rem solid $taubenrot;
  border-radius: 50%;
  width: $thumb_size;
  height: $thumb_size;
  padding: 0;
  display: flex;
  overflow: hidden;
  position: relative;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  z-index: 2;
  flex-shrink: 0;

  transition: box-shadow 0.2s ease-in;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.8) 5px 5px 1em inset;
  }
}

.station__content {
  background-color: fade-out($white, .5);
  transition: transform .1s ease-in;
  z-index: 3;
  padding: .2em .5em;

  .station:hover & {
    transform: scale(1.03);
  }
}

.station__thumb {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $freude-dark;
}
.station__title {
  font-size: 1.2em;
  display: block;
  font-weight: 600;
  font-family: $family-accent;
}
.station__date {
  color: $primary;
  font-weight: normal;
  size: 1rem;
}
