@import "bulma/sass/layout/section.sass";

.section {
  clear: both;

  &.is-wide > .container {
    @include widescreen {
      max-width: 100%;
      width: 100%; } }

  &.alternate {
    background-color: $freude-dark; } }

