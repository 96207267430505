$quote-image-size: 20em;

section.alternate--quote {
  padding: 0; }

blockquote.verses > p {
  max-width: 26em; }

.quote {
  display: flex;
  max-width: 100%;
  position: relative;
  min-height: 15em;

  // TODO: Change for different viewports
  justify-content: center;

  *:not(.alternate) > & {
    margin-top: $gap;
    margin-bottom: $gap; }

  > blockquote {
    align-self: center;
    margin: .5rem;
    padding: 0;
    font-size: 1.2em;
    font-style: italic;

    width: 55rem;
    background: transparent;
    border: none;

    &:not(.verses) {
      text-align: center; }

    &.verses {
      text-align: left; }

    &:only-child {
      margin: auto; }

    @include desktop {
      font-size: 1.4em; }


    > p {
      &::before,
      &::after {
        font-size: 2rem;
        line-height: 1.4rem;
        color: $secondary; }

      &:first-of-type::before {
        content: "„"; }

      &:last-of-type::after {
        content: "“"; } } }

  > figure.quote__image {
    width: 30%;

    // TODO: Change for different viewports
    min-width: $quote-image-size;

    //overflow: hidden;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 $gutter !important;

    > img {
      object-fit: cover;
      position: absolute;

      /*top: 0;
       *left: 0;
       *width: 100%;
       *height: 100%; */
      width: $quote-image-size;
      height: $quote-image-size;
      border-radius: 50%; }

    @include mobile {
      min-width: $quote-image-size * 0.5;
      margin: 0;

      > img {
        width: $quote-image-size * 0.5;
        height: $quote-image-size * 0.5; } }


    > .image-attribution {
      top: inherit;
      margin-top: $quote-image-size * 0.65;
      background: rgba(255, 255, 255, 0.3);
      text-align: center;
      transition: background .4s ease; }

    &:hover {
      .image-attribution {
        background: rgba(255, 255, 255, 0.6); } }

    @include mobile {
      min-width: $quote-image-size * 0.5;

      > img {
        width: $quote-image-size * 0.5;
        height: $quote-image-size * 0.5; }

      > .image-attribution {
        margin-top: $quote-image-size * 0.5 * 0.65; } } }

  .author--name {
    display: block;
    font-style: normal;
    font-size: .8em;
    line-height: 1.2;
    margin-top: .5em;

    > strong {
      text-transform: uppercase;
      font-family: "Ubuntu Medium", Ubuntu, sans-serif;
      font-weight: normal;
      color: inherit; } }

  .author--occupation,
  .author--source {
    font-size: .7em;
    display: block;
    font-style: normal;
    padding: 0 ($gap * 2); } }

$image-pill-small: 5em;
$image-pill-large: 7em;
$image-pill-treshold: 800px;

%image-pill {
  width: 5em;
  height: 5em;
  border-radius: 50%;
  background-color: $accent;
  margin: .5em;
  overflow: hidden;

  > img {
    width: 100%; }

  @include desktop {
    width: 7em;
    height: 7em; } }
