@import "bulma/sass/components/pagination.sass";

.pagination > * {
  display: flex;
  width: 50%;

  &:first-child:not(.pagination-list) {
    justify-content: flex-end; }

  &.pagination-list {
    justify-content: center;

    > h3 {
      width: 100%;
      text-align: center;
      margin-bottom: $gap; } } }

.postlink {
  height: auto;
  flex-direction: column;

  &.pagination-previous {
    align-items: flex-end; }
  &.pagination-next {
    align-items: flex-start; }

  &-date {
    display: block;
    font-size: 0.9em;
    color: $mine-shaft; } }
