.border {
  border: 2px solid $primary; }

.border-1 {
  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
  border-bottom-right-radius: 225px 15px;
  border-bottom-left-radius: 15px 255px; }

.border-2 {
  border-top-left-radius: 125px 25px;
  border-top-right-radius: 10px 205px;
  border-bottom-right-radius: 20px 205px;
  border-bottom-left-radius: 185px 25px; }

.border-3 {
  border-top-left-radius: 15px 225px;
  border-top-right-radius: 255px 15px;
  border-bottom-left-radius: 225px 15px;
  border-bottom-right-radius: 15px 255px; }

.border-4 {
  border-top-left-radius: 15px 225px;
  border-top-right-radius: 25px 150px;
  border-bottom-left-radius: 25px 115px;
  border-bottom-right-radius: 155px 25px; }

.border-5 {
  border-top-left-radius: 250px 15px;
  border-top-right-radius: 25px 80px;
  border-bottom-left-radius: 20px 115px;
  border-bottom-right-radius: 15px 105px; }

.border-6 {
  border-top-left-radius: 28px 125px;
  border-top-right-radius: 100px 30px;
  border-bottom-right-radius: 20px 205px;
  border-bottom-left-radius: 15px 225px; }

@mixin child-borders {
  @for $i from 1 through 6 {
    > :nth-child(6n+#{$i}) {
      @extend .border;
      @extend .border-#{$i}; } } }

.child-borders {
  @include child-borders; }
