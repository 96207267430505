.events {
  column-width: 20em;
  column-gap: 2.4em; }

h3.events__day {
  font-family: $family-primary;
  color: $grey-dark;
  font-size: 1.3rem;
  margin: 0;
  padding: 0; }

.event {
  display: inline-block;
  width: 100%;
  + .event {
    margin-top: .5em; } }

.event__time, .event__summary {
  font-weight: 500;
  display: inline;
  font-size: 1.3rem; }

.event__location {
  float: right;
  font-family: $family-accent;
  color: $taubenrot; }
