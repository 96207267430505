$social-icon-size: 2em;

.social-icon {
  width: $social-icon-size;
  height: $social-icon-size;
  vertical-align: middle;

  > use.icon {
    fill: currentColor; } }

.social-icons path {
  fill: inherit; }
